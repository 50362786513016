'use client';

import { useEffect, useState, useMemo } from 'react';

import NavBar from '@/components/nav-bar/nav-bar';
import ErrorPageComponent from '@/components/error-page/error-page';

import { User } from '@/interfaces';
import { getRequestTyped, postRequest } from '@/server-actions/common';

import Loading from './loading';

interface ErrorPageProps {
  reset: () => void;
}

export default function ErrorPage({ reset }: ErrorPageProps) {
  const [currentPageAccess, setCurrentPageAccess] = useState<string[] | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const [pageAccess, userInfo] = await Promise.all([
        getRequestTyped<string[]>('user/get-page-access', ['PageAccess']),
        getRequestTyped<User>('user/get-user', ['UserInfo']),
      ]);

      setCurrentPageAccess(pageAccess ?? null);
      setCurrentUserInfo(userInfo ?? null);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currentUserInfo) {
      postRequest('user/report-application-error', {
        email: currentUserInfo?.email,
        name: currentUserInfo?.name,
        surname: currentUserInfo?.surname,
        userAgent: navigator.userAgent,
        screenResolution: screen.width + 'x' + screen.height,
      });
    }
  }, [currentUserInfo]);

  const userInitials = useMemo(() => {
    const firstName = currentUserInfo?.name;
    const lastName = currentUserInfo?.surname;

    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`;
    }

    return '';
  }, [currentUserInfo?.name, currentUserInfo?.surname]);

  const isUserLoggedIn = !!(currentPageAccess && currentPageAccess.length > 0 && currentUserInfo);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isUserLoggedIn && (
        <NavBar
          pageAccess={currentPageAccess}
          userInitials={userInitials}
          userSubscription={currentUserInfo.subscription}
          isBetaUser={!!currentUserInfo.type?.isBetaUser}
          isEmergnUser={!!currentUserInfo.type?.isEmergnUser}
        />
      )}
      <ErrorPageComponent isUserLoggedIn={isUserLoggedIn} reset={reset} />
    </>
  );
}
