import React from 'react';
import clsx from 'clsx';

import { IdsButton, IdsCol, IdsContainer, IdsIcon, IdsLink, IdsRow, IdsText } from '@emergn-infinity/ids-react';

import { useWindowDimensions } from '@/hooks';
import { PRAXIS_SUPPORT_EMAIL_ADDRESS } from '@/constants/general';

import './error-page.scss';

interface ErrorPageComponentProps {
  isUserLoggedIn: boolean;
  reset: () => void;
}

const ErrorPageComponent: React.FC<ErrorPageComponentProps> = ({ isUserLoggedIn, reset }) => {
  const { isSmallMobile } = useWindowDimensions();

  return (
    <IdsContainer
      fixedWidthSize="xl"
      customClasses={clsx('ErrorPageContainer', isUserLoggedIn && 'IsUserLoggedIn')}
      fullHeight
    >
      <IdsRow horizontalAlign="center" verticalAlign="middle">
        <IdsCol customClasses="ErrorPageContent">
          <IdsIcon icon="ui-device-wall_socket" size="xl" customClasses="ErrorPageIcon" />
          <IdsText
            component="h2"
            size={isSmallMobile ? 'lg' : 'xl'}
            weight="bold"
            alignment="center"
            customClasses="ErrorPageTitle"
          >
            Oops, something went wrong!
          </IdsText>
          <div>
            {!isUserLoggedIn && (
              <IdsText isInline alignment="center">
                Contact us at{' '}
                <IdsLink href={`mailto:${PRAXIS_SUPPORT_EMAIL_ADDRESS}`} size="md" isInline>
                  {PRAXIS_SUPPORT_EMAIL_ADDRESS}
                </IdsLink>{' '}
                for assistance.
              </IdsText>
            )}
            {isUserLoggedIn && (
              <IdsText isInline alignment="center">
                Our support team has been notified and is investigating. No action is needed on your part.
              </IdsText>
            )}
            <IdsText alignment="center">You can try reloading the page or check back later.</IdsText>
          </div>
          <IdsButton variant="primary" clickHandler={reset} iconRight="themes-miscellaneous-redo">
            Reload page
          </IdsButton>
        </IdsCol>
      </IdsRow>
    </IdsContainer>
  );
};

export default ErrorPageComponent;
